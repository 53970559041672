@use "../ui-kit/colors.scss" as c;
@use "../ui-kit/devices.scss";
@import "/src/ui/ui-kit/mixins.scss";

.account-overview-wrapper {
  padding: 20px 20px 0 20px;
  font-size: 1rem;
  height: 100%;
  width: 100%;
  overflow: auto;

  .icon-wizard-info {
    cursor: help;
    color: var(--new-leaf-green-7);
    font-size: 20px;
  }

  @include devices.desktop {
    padding: 0 1.5rem 0 1.5rem;
  }

  .account-overview-table {
    position: relative;
    max-width: 1280px;

    @include devices.mobile {
      overflow: auto;
    }

    .no-wrap-cell {
      white-space: nowrap;
    }

    .account-overview-table-body {
      overflow: auto;
      padding-bottom: 50px;

      .account-overview-no-data-block {
        position: absolute;
        bottom: -40px;
        left: 0;
        width: 100%;
        height: 80px;
        background-color: var(--deepest-blue-3);
        border: 1px solid var(--deepest-blue-4);
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: var(--deepest-blue-7);
          @include fs-16;
        }
      }
    }

    .row-overlay-menu {
      div {
        &:last-child {
          border-radius: 0;
          background-color: var(--deepest-blue-2);

          @include devices.desktop {
            min-width: 250px;
          }

          @include devices.tablet {
            min-width: 250px;
          }
        }
      }

      i {
        color: var(--deepest-blue-7);
        transform: rotate(90deg);
      }
    }

    .table-wrapper {
      width: 100%;
      @include fs-12;
      text-align: left;

      thead {
        @include fs-12;

        tr {
          th {
            height: 48px;
            background-color: var(--deepest-blue-1);
            padding: 0 12px !important;

            &:first-child {
              text-transform: uppercase;
              min-width: 70px;//is set for ID column to have constant width to prevent shaking during sorting
            }

            &:not(:first-child):not(:last-child) {
              border-left: 1px solid var(--deepest-blue-4);
              border-right: 1px solid var(--deepest-blue-4);
            }
          }
        }
      }

      tbody {
        tr {
          background-color: var(--deepest-blue-3);

          &:hover {
            background-color: var(--deepest-blue-4);
            cursor: pointer;

            td {
              border-color: var(--deepest-blue-3);
            }
          }
        }

        td {
          border: 1px solid var(--deepest-blue-4);
          padding: 12px !important;

          &.hover-content {
            @include devices.mobile {
              padding: 20px !important;
            }
          }
        }
      }
    }
  }

  .account-overview-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 13px;

    button {
      background: var(--new-leaf-green-5);
      color: var(--deepest-blue-2);
      border-radius: 0;
      padding: 8px 12px;

      span {
        display: flex;
        align-items: center;
      }

      i {
        font-size: 24px;
        margin-right: 10px;
      }
    }
  }

  .acc-enable-window {
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 2;

    .dialog-block {
      margin: auto 20px;
      z-index: 3;
      border: 1px solid var(--deepest-blue-4);
      background-color: var(--deepest-blue-2);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      max-width: 500px;

      @include devices.desktop {
        margin: auto;
      }

      .dialog-button {
        margin: 2em 0 1em 50%;
        text-align: center;
        transform: translate(-50%);

        .main-button {
          padding: 0.5em 2em;
        }

        a {
          color: var(--color-white);
          text-decoration: none;

          &:hover {
            color: var(--color-white);
          }
        }
      }

      h4 {
        @include fs-18;
        font-family: "Syne";
        font-weight: 600;
        border-top: 8px solid var(--new-leaf-green-5);
        border-bottom: 1px solid var(--deepest-blue-4);
        color: var(--new-leaf-green-5);
        padding: 15px 20px;
        width: 100%;
      }

      hr {
        margin-bottom: 0;
        color: var(--deepest-blue-4);
        opacity: 1;
      }

      .dialog-body-block {
        @include fs-14;
        padding: 0 20px 20px 20px;
        text-align: left;
        margin-top: 1.5rem;

        div {
          &:first-child {
            @include fs-16;
          }
        }

        p {
          margin-bottom: 8px;
        }

        ul {
          list-style: none;
          padding-left: 20px;
          margin-bottom: 8px;

          li {
            margin-bottom: 8px;

            span {
              &:last-child {
                margin-left: -15px;
              }
            }

            img {
              transform: translateX(-20px) rotateZ(270deg);
            }
          }
        }
      }
    }

    .dialog-block-background {
      position: fixed;
      width: 100vw;
      height: 100vh;
      background-color: #0000003d;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  hr {
    margin: 25px 0;
  }

  .account-overview-header {
    line-height: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 23px;

    @include devices.desktop {
      margin-top: 18px;
    }

    h3 {
      color: var(--cool-gray-10);
      font-weight: bold;
      text-align: initial;
      font-family: "Syne";
      @include fs-20;
      letter-spacing: 0.4px;

      @include devices.mobile {
        @include fs-16;
        line-height: 16px;
      }
    }

    span {
      color: var(--deepest-blue-8);
      @include fs-14;

      @include devices.mobile {
        @include fs-10;
      }

      &.semi-bold {
        color: var(--color-white);
      }
    }

    .account-overview-header-top {
      display: flex;
      align-items: center;
      gap: 24px;

      @include devices.mobile {
        gap: 5px;
      }
    }
  }

  .account-summary-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.9em;

    .account-title {
      position: relative;
      font-family: "Syne";
      font-size: 1rem;
      line-height: 24px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      .so-far-this-month-title-tooltip {
        @include fs-12;
        font-weight: 300;
      }

      i {
        margin-left: 5px;
        position: relative;
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
    }

    .account-summary-body {
      display: grid;
      grid-template-columns: repeat(auto-fill, 255px);
      grid-gap: 15px;
      width: 100%;
      max-width: 800px;

      @include devices.mobile {
        grid-template-columns: 1fr;
        gap: 0.5rem;
      }
    }
  }

  .trad-platform-block {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2em;
    font-size: 0.9em;

    .account-title {
      margin-right: 1em;
    }

    span {
      margin-right: 2em;
    }
  }

  .market-data-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.9em;

    .market-data-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .risk-settings-block {
    display: flex;
    margin-top: 32px;
    flex-direction: column;
    align-items: flex-start;
    @include fs-14;

    .account-title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .rs-title-tooltip {
        text-align: left;
        @include fs-12;
        font-weight: 300;

        a {
          color: inherit;
          cursor: pointer;
        }
      }

      i {
        cursor: help;
        margin-left: 5px;
      }
    } 

    .risk-settings-body {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
  }

  h6 {
    font-family: "Syne";
    color: var(--deepest-blue-8);
    font-family: "Syne";
    font-weight: bold;

    img {
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .body-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: initial;
    padding: 4px 12px;
    gap: 10px;
    background-color: var(--deepest-blue-3);

    &:hover {
      background-color: var(--deepest-blue-4);
    }

    .test-family {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
    }

    .font-size-body {
      color: var(--deepest-blue-8);
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.5px;
    }
  }

  .this-month-body-item {
    position: relative;
    background-color: var(--deepest-blue-3);
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 1.1em;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 10px 14px;

    @include devices.mobile {
      padding: 15px 20px;
      min-height: 90px;
    }

    span:first-child {
      color: var(--deepest-blue-9);
      @include fs-24;
      font-weight: 600;

      @include devices.mobile {
        @include fs-28;
      }
    }

    span {
      color: var(--deepest-blue-9);
      @include fs-12;
      font-weight: 500;

      @include devices.mobile {
        @include fs-14;
      }
    }
  }
}
